<template>
<div>
    <div style="background:#fff">
        <ListItem v-for="newItem in newsList" :key="newItem.News_ID" :ids="newItem.News_ID" :title="newItem.News_Title" :reads="newItem.News_Count" :imgsrc="newItem.News_SmallImage" />
        <a href="javascript:void(0);" class="more-data" @click="loadmore">加载更多</a>
    </div>

</div>
</template>

<script>
export default {
    data() {
        return {
            newsList: [],
            pageNewsIndex: 1,
            sortNum:1,
        };
    },
    created() {
        this.sortNum = this.$route.params.sort
        this.getNewsList();
    },
    methods: {
        getNewsList() {
            this.wpost({
                url: "/Ajax/Member/getnewslist",
                data: {
                    pagesize: 20,
                    pageindex: this.pageNewsIndex,
                    sort: this.sortNum
                },
                succ: res => {
                    console.log("newsList:", res);
                    this.newsList = res.list;
                }
            });
        },
        loadmore() {
            console.log("loadmore")
            this.wpost({
                url: "/Ajax/Member/getnewslist",
                data: {
                    sort: this.sortNum,
                    pageindex: (this.pageNewsIndex += 1)
                },
                succ: (data) => {
                    if (data.list.length > 0) {
                        this.newsList = this.newsList.concat(data.list);
                    } else {
                        alert("暂无更多相关数据...")
                        this.pageNewsIndex -= 1;
                    }
                }
            });
        }
    }
};
</script>

<style scoped>
</style>
